/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Galerie"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-171y10h --style2 --full pb--60 pt--60" name={"cyfs9lw05h4"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/7e71b7927ede49eeb497586360414c6d_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/7e71b7927ede49eeb497586360414c6d_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/7e71b7927ede49eeb497586360414c6d_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/7e71b7927ede49eeb497586360414c6d_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/7e71b7927ede49eeb497586360414c6d_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/7e71b7927ede49eeb497586360414c6d_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/7e71b7927ede49eeb497586360414c6d_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/7e71b7927ede49eeb497586360414c6d_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"<span style=\"color: var(--white)\">Galerie</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"69ybfgsjn93"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/d5168c1d7a0e44d1a9d77e059bfc394d_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/d5168c1d7a0e44d1a9d77e059bfc394d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/d5168c1d7a0e44d1a9d77e059bfc394d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/d5168c1d7a0e44d1a9d77e059bfc394d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/d5168c1d7a0e44d1a9d77e059bfc394d_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/b9d221a757a84b69be76cd651620d00f_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":722}} srcSet={"https://cdn.swbpg.com/t/18947/b9d221a757a84b69be76cd651620d00f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/b9d221a757a84b69be76cd651620d00f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/b9d221a757a84b69be76cd651620d00f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/b9d221a757a84b69be76cd651620d00f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/b9d221a757a84b69be76cd651620d00f_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/da9c495e65ea4fe5ab9f308c8b6ad4c4_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":698}} srcSet={"https://cdn.swbpg.com/t/18947/da9c495e65ea4fe5ab9f308c8b6ad4c4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/da9c495e65ea4fe5ab9f308c8b6ad4c4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/da9c495e65ea4fe5ab9f308c8b6ad4c4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/da9c495e65ea4fe5ab9f308c8b6ad4c4_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/b78d13fa4b7841f0be9e5ca7a94c0a81_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":710}} srcSet={"https://cdn.swbpg.com/t/18947/b78d13fa4b7841f0be9e5ca7a94c0a81_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/b78d13fa4b7841f0be9e5ca7a94c0a81_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/b78d13fa4b7841f0be9e5ca7a94c0a81_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/b78d13fa4b7841f0be9e5ca7a94c0a81_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/b78d13fa4b7841f0be9e5ca7a94c0a81_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":708}} srcSet={"https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=1400x_.png 1400w, https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=2000x_.png 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/4313f8dedd93446dacd8687a9c500c01_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":730}} srcSet={"https://cdn.swbpg.com/t/18947/4313f8dedd93446dacd8687a9c500c01_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/4313f8dedd93446dacd8687a9c500c01_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/4313f8dedd93446dacd8687a9c500c01_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/4313f8dedd93446dacd8687a9c500c01_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/be1533f4cd8a498392411a6b5731e443_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/be1533f4cd8a498392411a6b5731e443_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/be1533f4cd8a498392411a6b5731e443_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/be1533f4cd8a498392411a6b5731e443_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/be1533f4cd8a498392411a6b5731e443_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/1e4aac034c094ca2987124f2eddac4c0_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":682}} srcSet={"https://cdn.swbpg.com/t/18947/1e4aac034c094ca2987124f2eddac4c0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/1e4aac034c094ca2987124f2eddac4c0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/1e4aac034c094ca2987124f2eddac4c0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/1e4aac034c094ca2987124f2eddac4c0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/1e4aac034c094ca2987124f2eddac4c0_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":734}} srcSet={"https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/7e612cb257854605a61556b4b5d7c61f_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":718}} srcSet={"https://cdn.swbpg.com/t/18947/7e612cb257854605a61556b4b5d7c61f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/7e612cb257854605a61556b4b5d7c61f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/7e612cb257854605a61556b4b5d7c61f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/7e612cb257854605a61556b4b5d7c61f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/7e612cb257854605a61556b4b5d7c61f_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/c1863f50158744d4a305e56bb8d816f2_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":722}} srcSet={"https://cdn.swbpg.com/t/18947/c1863f50158744d4a305e56bb8d816f2_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/c1863f50158744d4a305e56bb8d816f2_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/c1863f50158744d4a305e56bb8d816f2_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/c1863f50158744d4a305e56bb8d816f2_s=1400x_.png 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/e59b41df769c41748b64c348ca6c9e25_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":718}} srcSet={"https://cdn.swbpg.com/t/18947/e59b41df769c41748b64c348ca6c9e25_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/e59b41df769c41748b64c348ca6c9e25_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/e59b41df769c41748b64c348ca6c9e25_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/e59b41df769c41748b64c348ca6c9e25_s=1400x_.png 1400w, https://cdn.swbpg.com/t/18947/e59b41df769c41748b64c348ca6c9e25_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60">
          
          <ColumnWrap className="column__flex el--4 pl--0 pr--0 flex--top" columns={"4"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Image className="--left" style={{"maxWidth":192}} alt={""} src={"https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">E-mail</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"info@bistronarodnimuzeum.cz"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">Telefon</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"+420 257 310 852"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">Sledujte nás</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"<a href=\"https://www.facebook.com/bistronarodnimuzeum\" target=\"_blank\">Facebook</a>"}>
              </Text>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"<a href=\"https://www.instagram.com/bistronarodnimuzeum/\" target=\"_blank\">Instagram</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}